import type { SubscriptionGetAllIncludableFields } from '~/composables/api/subscription/useFetchSubscription'

export const useSubscription = ({ fieldsToInclude = ['scheduled_changes'] }: { fieldsToInclude?: SubscriptionGetAllIncludableFields[] } = {}) => {
  const { workspace } = useAuth()
  const isLoadingSubscriptionsEnabled = computed(() => !!workspace.value)
  const { data: subscriptionsData, isPending } = useQuerySubscription.all({
    fieldsToInclude,
    useQueryOptions: {
      enabled: isLoadingSubscriptionsEnabled,
    },
  })

  const subscriptions = computed(() => subscriptionsData.value?.data ?? [])

  const mainSubscription = computed<App.Data.Subscription.SubscriptionData | undefined>(() => {
    // Backend returns all subscriptions, even cancelled and trial subscriptions.
    // Main subscription is the first active subscription and if no active, first trial
    // subscription. Workspace can have one active and one trial subscription in some cases
    const statuses: App.Internals.Enums.SubscriptionStatus[] = [
      'active',
      'non_renewing',
      'in_trial',
      'paused',
    ]

    for (const status of statuses) {
      const subscription = subscriptions.value?.find(({ status: currentStatus }) => currentStatus === status)
      if (subscription)
        return subscription
    }

    return subscriptions.value?.find(({ status }) => status !== 'cancelled')
  })

  const hasValidSubscription = computed(() =>
    (subscriptions.value || []).some(
      ({ status }) => ['active', 'non_renewing', 'in_trial'].includes(status),
    ),
  )

  const hasPaidSubscription = computed(() =>
    (subscriptions.value || []).some(
      ({ status }) => ['active', 'non_renewing'].includes(status),
    ),
  )

  const trialSubscription = computed(() =>
    subscriptions.value?.find(({ status }) => status === 'in_trial'),
  )

  const hasTrialSubscription = computed(() => !!trialSubscription.value)

  const mainSubscriptionStatus = computed(() => mainSubscription.value?.status)

  const isMainSubscriptionActive = computed(() => mainSubscriptionStatus.value === 'active')
  const isMainSubscriptionPaused = computed(() => mainSubscriptionStatus.value === 'paused')
  const isMainSubscriptionInTrial = computed(() => mainSubscriptionStatus.value === 'in_trial')
  const isMainSubscriptionNonRenewing = computed(() => mainSubscriptionStatus.value === 'non_renewing'
    || !!mainSubscription.value?.cancelled_at,
  )
  const isMainSubscriptionSetup = computed(() =>
    isMainSubscriptionInTrial.value && mainSubscription.value?.trial_end_action === 'activate_subscription',
  )
  const isMainSubscriptionStartingInFuture = computed(() => mainSubscriptionStatus.value === 'future')

  const mainSubscriptionScheduledChanges = computed(() => mainSubscription.value?.scheduled_changes)

  return {
    isSubscriptionFetchPending: isPending,

    mainSubscription,
    trialSubscription,

    mainSubscriptionScheduledChanges,

    hasPaidSubscription,
    hasValidSubscription,
    hasTrialSubscription,

    isMainSubscriptionActive,
    isMainSubscriptionInTrial,
    isMainSubscriptionPaused,
    isMainSubscriptionNonRenewing,
    isMainSubscriptionSetup,
    isMainSubscriptionStartingInFuture,
  }
}
